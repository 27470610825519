import React from "react"
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroller"

import { Layout } from "../components/layout"
import { SearchInput } from "../components/SearchInput"
import { ProductItemCard } from "../components/ProductItemCard"
import SEO from "../components/seo"
import { TagFilter } from "../components/TagFilter"
import { withSearch, WithSearchWrappedProps } from "../components/withSearch"
import { SearchOrder, useSearchParameters } from "../useSearchParameters"
import { ProductItemCardFragment } from "src/components/ProductItemCard/__generated__/ProductItemCardFragment"

const SearchPage = ({
  data,
  hasMore,
  histogramResults,
  isLoading,
  loadMore,
}: WithSearchWrappedProps) => {
  const [mobileTagFilterOpen, setMobileTagFilterOpen] = React.useState(false)
  const [query] = useSearchParameters()

  const handleCloseMobileTagFilter = () => setMobileTagFilterOpen(false)

  const handleOpenMobileTagFilter = () => setMobileTagFilterOpen(true)

  const productItems =
    data?.map(product => (
      <ProductListItemMemo key={product.id} product={product} />
    )) ?? []

  return (
    <Box display="flex">
      <TagFilter
        mobileTagFilterOpen={mobileTagFilterOpen}
        handleCloseMobileTagFilter={handleCloseMobileTagFilter}
        queryHistogramResult={histogramResults}
      />
      <Layout headerProps={{ showSearch: false }}>
        <SEO title={`Search results for ${query.q}`} />
        <SearchInput initialQuery={query.q}></SearchInput>
        <Box display={{ xs: "block", lg: "none" }} marginY={1}>
          <Button
            onClick={handleOpenMobileTagFilter}
            variant="outlined"
            color="secondary"
          >
            Filter
          </Button>
        </Box>

        <Box mt={4}>
          <Typography variant="h5" component="h1">
            Results
          </Typography>
        </Box>
        <Box height={2} mt={1}>
          {isLoading && <LinearProgress />}
        </Box>
        <Divider />
        <Box mt={2}>
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} loader={loader}>
            <Grid key="grid-key" container spacing={1} direction="row">
              {productItems}
            </Grid>
          </InfiniteScroll>
          {!isLoading && productItems.length === 0 && (
            <Box>
              <Typography>No results found for your search</Typography>
            </Box>
          )}
        </Box>
      </Layout>
    </Box>
  )
}

const loader = (
  <Box
    key="loader-key"
    mt={8}
    display="flex"
    alignContent="center"
    justifyContent="center"
  >
    <CircularProgress></CircularProgress>
  </Box>
)

const ProductListItem: React.FC<{ product: ProductItemCardFragment }> = ({
  product,
}) => (
  <Grid md={4} xs={12} item>
    <Box display="flex" alignItems="center" justifyContent="center">
      <ProductItemCard product={product} />
    </Box>
  </Grid>
)

const ProductListItemMemo = React.memo(
  ProductListItem,
  (oldProps, newProps) => oldProps.product.id === newProps.product.id
)

export default withSearch(SearchPage, {
  staticSearchParams: { orderby: SearchOrder.SIMILARITY },
})
